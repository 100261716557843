




























































































import { getUserGameActionsSummary } from '@/api/ClientInfo';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import { DataTableHeader } from 'vuetify';
import { DateTime } from 'luxon';
import {
  ActionTypeNaming,
  EActionType,
  EGameType,
  GameTypeNaming,
  IDataSearchParamsRequest,
  TUserGameActionDataItem,
  TUserGameActionResponse
} from '@/api/schema';
import { errorToastMessage } from '@/helpers/errorToastMessage';

const defaultDates = {
  from: DateTime.now().startOf('year').toFormat('yyyy-LL-dd'),
  to: DateTime.now().toFormat('yyyy-LL-dd')
};

interface GgrBlockItem {
  title: string;
  value: number;
  iconColor: string;
  tooltip?: string;
}

interface IUserGameActionDataTableItem {
  name: string;
  amountUsd: number;
  count: number;
  sportBookGgr?: number;
}

interface IUserGameActionDataTableGroupItems
  extends IUserGameActionDataTableItem {
  groupItems: IUserGameActionDataTableItem[];
}

export default {
  name: 'UserManagementTotalGgrTab',
  components: {
    VExtendedDataPicker,
    Tooltip
  },
  data(): any {
    return {
      loading: false,
      dates: { ...defaultDates },
      summaryData: [],
      casinoGgr: 0,
      sportsBookGgr: 0,
      expanded: [],
      widthColumnParams: ['34%', '33%', '33%']
    };
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    operatorUserId: {
      type: String,
      default: (): string => null
    },
    operatorUserCreatedAt: {
      type: String,
      default: (): string => ''
    }
  },
  computed: {
    loadingUserDetails(): boolean {
      return !(this.operatorUserId || this.operatorUserCreatedAt);
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: 'Transaction Type',
          value: 'name',
          width: this.widthColumnParams[0]
        },
        {
          text: 'Quantity',
          value: 'count',
          width: this.widthColumnParams[1]
        },
        {
          text: 'Total Amount',
          value: 'amountUsd',
          width: this.widthColumnParams[2]
        }
      ];
    },
    ggrBlockItems(): GgrBlockItem[] {
      return [
        {
          title: 'Total GGR',
          value: this.totalGgr,
          iconColor: '#696CFF'
        },
        {
          title: 'Casino GGR',
          value: this.casinoGgr,
          iconColor: '#86888D'
        },
        {
          title: 'SportsBook GGR',
          value: this.sportsBookGgr,
          iconColor: '#86888D'
          // tooltip: 'test'
        }
      ];
    },
    totalGgr(): number {
      return this.casinoGgr + this.sportsBookGgr;
    },
    gameActionsSummaryItems(): IUserGameActionDataTableGroupItems[] {
      const actionTypesGroupConfig = {
        [EActionType.BET]: {
          isGrouped: true,
          gameTypes: [EGameType.CASINO, EGameType.SPORTSBOOK]
        },
        [EActionType.WIN]: {
          isGrouped: true,
          gameTypes: [EGameType.CASINO, EGameType.SPORTSBOOK]
        },
        [EActionType.ROLLBACK_BET]: {
          isGrouped: true,
          gameTypes: [EGameType.CASINO, EGameType.SPORTSBOOK]
        },
        [EActionType.ROLLBACK_WIN]: {
          isGrouped: true,
          gameTypes: [EGameType.CASINO, EGameType.SPORTSBOOK]
        }
        // Temporarily hidden
        // [EActionType.BET_COPIED]: {
        //   isGrouped: false,
        //   gameTypes: [EGameType.CASINO]
        // },
        // [EActionType.WIN_COPIED]: {
        //   isGrouped: false,
        //   gameTypes: [EGameType.CASINO]
        // },
        // [EActionType.ROLLBACK_BET_COPIED]: {
        //   isGrouped: false,
        //   gameTypes: [EGameType.CASINO]
        // },
        // [EActionType.ROLLBACK_WIN_COPIED]: {
        //   isGrouped: false,
        //   gameTypes: [EGameType.CASINO]
        // }
      };

      const defaultValues = {
        amountUsd: 0,
        count: 0,
        sportBookGgr: 0
      };

      const getItemSumData = (
        actionType: EActionType
      ): IUserGameActionDataTableItem => {
        return this.summaryData?.reduce(
          (
            result: IUserGameActionDataTableItem,
            obj: TUserGameActionDataItem
          ) => {
            if (actionType === obj.actionType) {
              result.amountUsd += obj.amountUsd;
              result.count += obj.count;
              result.sportBookGgr += obj.sportBookGgr;
            }
            return result;
          },
          { name: ActionTypeNaming[actionType], ...defaultValues }
        );
      };

      const getGroupItemsData = (
        actionType: EActionType
      ): IUserGameActionDataTableItem[] => {
        const getDataByType = (
          gameType: EGameType
        ): IUserGameActionDataTableItem => {
          const foundItem = this.summaryData?.find(
            (item: TUserGameActionDataItem) =>
              item.actionType === actionType && item.gameType === gameType
          );

          return {
            name: GameTypeNaming[gameType],
            ...defaultValues,
            ...(foundItem && {
              amountUsd: foundItem.amountUsd,
              count: foundItem.count,
              sportBookGgr: foundItem.sportsBookGgr
            })
          };
        };

        return actionTypesGroupConfig[actionType].gameTypes.map((gameType) =>
          getDataByType(gameType)
        );
      };

      return Object.keys(actionTypesGroupConfig).map(
        (actionType: EActionType) => {
          const sumData = getItemSumData(actionType);
          const groupItems = actionTypesGroupConfig[actionType].isGrouped
            ? getGroupItemsData(actionType)
            : undefined;

          return {
            ...sumData,
            ...(groupItems && { groupItems })
          };
        }
      );
    }
  },
  watch: {
    operatorUserId: {
      handler(newId: string | null): void {
        if (newId) {
          this.checkUserCreatedDate();
          this.getUserGameActionData();
        }
      },
      immediate: true
    }
  },
  methods: {
    getUserGameActionData(): Promise<unknown> {
      this.loading = true;

      const { from, to } = this.dates;
      const searchParams: IDataSearchParamsRequest = {
        startDate: from,
        endDate: to
      };

      return getUserGameActionsSummary(this.userId, searchParams)
        .then((data: TUserGameActionResponse): void => {
          this.casinoGgr = data.casinoGgr || 0;
          this.sportsBookGgr = data.sportsBookGgr || 0;
          this.summaryData = data.gameAction || {};
        })
        .catch(errorToastMessage)
        .finally((): void => {
          this.loading = false;
        });
    },
    checkUserCreatedDate(): void {
      const date = this.operatorUserCreatedAt;

      if (date) {
        const userCreatedDate = DateTime.fromFormat(
          date,
          'yyyy-MM-dd HH:mm:ss'
        );

        this.dates.from = userCreatedDate.toFormat('yyyy-MM-dd');
      } else {
        this.dates.from = defaultDates.from;
      }
    },
    toggleExpandedRow(item: TUserGameActionDataItem): void {
      if (this.isExpanded(item)) {
        this.expanded.splice(this.expanded.indexOf(item), 1);
      } else {
        this.expanded.push(item);
      }
    },
    isExpanded(item: TUserGameActionDataItem): boolean {
      return this.expanded.indexOf(item) !== -1;
    },
    onDateChange(): void {
      this.getUserGameActionData();
    }
  }
};
