var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',{attrs:{"disabled":_vm.loading}},[_c('v-card-title',{staticClass:"justify-space-between"},[_c('div',{staticClass:"ggr-value__block"},_vm._l((_vm.ggrBlockItems),function(ggrItem){return _c('div',{key:ggrItem.title,staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-2 mr-3",attrs:{"color":"#ffffff","size":"44","rounded":""}},[_c('v-icon',{attrs:{"color":ggrItem.iconColor},domProps:{"textContent":_vm._s('mdi-currency-usd')}})],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex text-body-2 font-weight-medium secondary--text"},[_vm._v(_vm._s(ggrItem.title)),(ggrItem.tooltip)?_c('Tooltip',{attrs:{"text":ggrItem.tooltip}}):_vm._e()],1),(!_vm.loading)?_c('div',{staticClass:"text-lg font-weight-bold"},[_vm._v(_vm._s(_vm._f("numeralSpaces")(ggrItem.value,'0,0.[00]'))+" USD")]):_vm._e()])],1)}),0),(!_vm.loadingUserDetails)?_c('v-extended-data-picker',{staticClass:"ml-5",attrs:{"range":"","max-today":""},on:{"input":_vm.onDateChange},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}):_vm._e()],1),_c('v-data-table',{staticClass:"summary-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.gameActionsSummaryItems,"items-per-page":-1,"item-key":"name","expanded":_vm.expanded,"hide-default-footer":"","disable-sort":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex align-center"},[(item.groupItems)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpandedRow(item, index)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.isExpanded(item) ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1):_vm._e(),_vm._v(_vm._s(item.name))],1)]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("numeralSpaces")(item.count,'0,0.[00]')))])]}},{key:"item.amountUsd",fn:function(ref){
var item = ref.item;
return [(item.amountUsd)?_c('strong',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm._f("numeralSpaces")(item.amountUsd,'0,0.[00]'))+" USD")]):_c('span',[_vm._v("0")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":"4"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":item.groupItems,"items-per-page":-1,"item-key":"dataKey","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{style:({ width: _vm.widthColumnParams[0] })},[_c('span',{staticClass:"ml-10"},[_vm._v(_vm._s(item.name))])]),_c('td',{style:({ width: _vm.widthColumnParams[1] })},[_c('span',[_vm._v(_vm._s(_vm._f("numeralSpaces")(item.count,'0,0.[00]')))])]),_c('td',{style:({ width: _vm.widthColumnParams[2] })},[(item.amountUsd)?_c('strong',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm._f("numeralSpaces")(item.amountUsd,'0,0.[00]'))+" USD")]):_c('span',[_vm._v("0")])])])]}}],null,true)})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }